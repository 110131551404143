/**
 * <PRE>
 * System Name : 사랑온 NEXT 시스템(TSS2-MSP)
 * Business Name : 청약
 * Class Name : PSElstUtil.js
 * Description :
 *              AS-IS 파일 PS110MUtil.js 대응
 *              전자서명사전입력처리유틸
 * </PRE>
 */
import ServiceManager from '~/src/ui/ps/comm/PSServiceManager'
import PSContRltnrUtil from '~/src/ui/ps/comm/PSContRltnrUtil'

const PSElstUtil = {}

PSElstUtil.glv_CustInfoAll = {}

PSElstUtil.fnCallBack = function () {}

PSElstUtil.inptDataObj = {} // 영수증번호등의 조회에 필요한 정보
PSElstUtil.selType = '0' // 조회유형
// 조회유형
// 0:기존조회 : 영수증번호로 심사결과조회 -> 계약자조회 -> 피보험자조회(선택)
//            : TSSPS111P:예금주선택화면 호출
// 1:예금주 조회 : 신규전자서명 TSSPS111P -> MSPPS420M
// 2:예금주 조회 : 중단건 조회 TSSPS213D -> MSPPS420M

/************************************************************************************************
 * Function명  : fn_EltrnSignBaseInfo
 * 설명        : 전자사명 필요한 기초 정보
 ************************************************************************************************/
PSElstUtil.fn_EltrnSignBaseInfo = function (serviceName, inputData, fnCallBack, srnId = 'MSPPS420M') {
  this.fnCallBack = fnCallBack
  ServiceManager.invokeServerDate()
  let lv_vue = this
  let context = window.vue.getInstance('vue')
  context.$options.screenId = srnId
  // 191217 예금주 추가 :
  this.inptDataObj = JSON.parse(inputData) // 입력 Data Object로 변경
  // 조회유형 설정
  PSElstUtil.selType = '0'
  if (this.inptDataObj.vuchId.trim() === '') {
    PSElstUtil.selType = '0' // 예금주선택
  } else if (this.inptDataObj.dpstrInfo.chnlCustId.trim() !== '') {
    PSElstUtil.selType = '1' // 신규전자서명
  } else {
    PSElstUtil.selType = '2' // 중단건조회
  }
  // FC 정보 조회
  ServiceManager.invokeCnsltHeaderInfo(context.$MenuManager.fn_GetUserInfoProp('userId'), context)
    .then(function (response) {
      lv_vue.fn_ServiceData(serviceName, inputData)
    }).catch(function (response) {
    })
}
/************************************************************************************************
 * Function명  : fn_ServiceData
 * 설명        : 서비스 호출 함수 정의
 ************************************************************************************************/
PSElstUtil.fn_ServiceData = function (serviceName, inputData) {
  let pParams = {}
  switch (serviceName) {
    case 'txTSSPS13S2': // 심사결과 정보 호출(계약자/피보험자/수익자/보험정보)
      pParams = Object.assign({}, ServiceManager.getParameter('txTSSPS13S2'))
      pParams.context = Object.assign(window.vue.getInstance('vue'), this)
      pParams.useCompress = true
      pParams.srnId = 'MSPPS420M' // 화면명 추가
      pParams.data = {}
      pParams.data.cmndSrvcNm = 'selGafApplicationMain'
      pParams.data.sdrvDtVal = inputData
      break
    case 'S2': // 채널고객 기본 정보(계약자)
    case 'S3': // 채널고객 기본 정보(피보험자)
    case 'S5': // 채널고객 기본 정보(예금주) : 191217 추가
      pParams = Object.assign({}, ServiceManager.getParameter('txTSSPS13S4'))
      pParams.context = this
      pParams.srnId = 'MSPPS420M' // 화면명 추가
      pParams.data = {}
      pParams.data.cnsltNo = ServiceManager.getUserInfo('userId')
      pParams.data.chnlCustId = inputData.agtFileId
      pParams.data.custNm = inputData.insrdNm
      break
    case 'S4': // 전자서명진행상태조회 : 191217 추가
      pParams = Object.assign({}, ServiceManager.getParameter('txTSSPS98S1'))
      pParams.context = this
      pParams.srnId = 'MSPPS420M' // 화면명 추가
      pParams.data = {}
      pParams.data.vuchId = inputData.vuchId
      break
    case 'S6': // 예금주의 BPID조회 : 191217 추가
      pParams = Object.assign({}, ServiceManager.getParameter('txTSSPS13S6'))
      //pParams = Object.assign({}, ServiceManager.getParameter('txTSSPS13S10')) //정보보호 서비스 주민번호 암호화된 값으로 보내야 함
      pParams.data.BUSINESSPARTNER.GENERALDATA_S = {}
      pParams.data.BUSINESSPARTNER.GENERALDATA_S.BP_PERSON = {}
      pParams.data.BUSINESSPARTNER.GENERALDATA_S.BP_PERSON.LASTNAME = inputData.dpstrNm
      pParams.data.BUSINESSPARTNER.IDNUMBERS_T = [{IDENTIFICATIONNUMBER: inputData.dpstrKnb}]
      pParams.data.IS_HEADER.USERID = ServiceManager.getUserInfo('userId')
      break
    default:
      break
  } // end switch
  ServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler)
}
/************************************************************************************************
 * Function명  : fn_ServiceResultHandler
 * 설명        : 서비스 호출 결과 함수 정의
 ************************************************************************************************/
PSElstUtil.fn_ServiceResultHandler = function (event, serviceName) {
  let resData = event.data
  let inputDataTemp = {}
  switch (serviceName) {
    case 'txTSSPS13S2': // 심사결과 정보 호출(계약자/피보험자/수익자/보험정보)
      this.glv_CustInfoAll = PSContRltnrUtil.fn_PICustInfoAll(resData)
      this.glv_CustInfoAll.dpstrInfo = {}
      if (this.glv_CustInfoAll && this.glv_CustInfoAll.hoshInfoList) {
        this.glv_CustInfoAll.hoshInfoList[0].detail = {}
        // 2020/12 : 모바일청약 2단계 : 단체명의 개인보험 가입가능
        // 고객상세유형코드
        // 11:내국인, 12:외국인 > agtFileId 있음 > 상세조회가능
        // 21:법인사업자, 22:개인사업자, 23:임의단체 > agtFileId 없음
        if (this.glv_CustInfoAll.hoshInfoList[0].cusDtlTypCd === '11' || this.glv_CustInfoAll.hoshInfoList[0].cusDtlTypCd === '12') {
          this.fn_ServiceData('S2', this.glv_CustInfoAll.hoshInfoList[0]) // 계약자
        } else {
          this.fn_ServiceData('S3', this.glv_CustInfoAll.hoshInfoList[1]) // 피 보험자
        }
      } // end if
      break
    case 'S2': // 채널고객 기본 정보(계약자)
      this.glv_CustInfoAll.hoshInfoList[0].detail = PSContRltnrUtil.fn_CustInfoList(resData) // 계약자 상세정보
      if (this.glv_CustInfoAll.hoshInfoList.length > 1) {
        this.glv_CustInfoAll.hoshInfoList[1].detail = {}
        this.fn_ServiceData('S3', this.glv_CustInfoAll.hoshInfoList[1]) // 피 보험자
      } // end if
      break
    case 'S3': // 채널고객 기본 정보(피보험자)
      this.glv_CustInfoAll.hoshInfoList[1].detail = PSContRltnrUtil.fn_CustInfoList(resData) // 피 보험자 상세정보
      if (PSElstUtil.selType === '0') {
        this.fnCallBack(this.glv_CustInfoAll)
      } else if (PSElstUtil.selType === '1') { // 신규전자서명
        let inputCust = {}
        inputCust.agtFileId = this.inptDataObj.dpstrInfo.chnlCustId
        inputCust.insrdNm = this.inptDataObj.dpstrInfo.custNm
        // 예금주관계정보설정
        this.glv_CustInfoAll.dpstrInfo.contrDpstrSameYn = this.inptDataObj.dpstrInfo.contrDpstrSameYn // 계약자예금주동일여부
        this.glv_CustInfoAll.dpstrInfo.insrdDpstrSameYn = this.inptDataObj.dpstrInfo.insrdDpstrSameYn // 피보험자예금주동일여부
        this.glv_CustInfoAll.dpstrInfo.dpstrIdpnYn = this.inptDataObj.dpstrInfo.dpstrIdpnYn // 예금주독립여부
        this.fn_ServiceData('S5', inputCust)
      } else if (PSElstUtil.selType === '2') { // 중단건조회
        let inputVuchId = {}
        inputVuchId.vuchId = this.inptDataObj.vuchId
        // 예금주관계정보설정  : 전자서명진행상태 조회 후 설정 함
        this.fn_ServiceData('S4', inputVuchId)
      }
      break
    case 'S4': // 전자서명진행상태조회 : 191217 추가
      this.glv_CustInfoAll.eltrnSaveData = resData
      this.glv_CustInfoAll.dpstrInfo.contrDpstrSameYn = this.glv_CustInfoAll.eltrnSaveData.contrDpstrSameYn // 계약자예금주동일여부
      this.glv_CustInfoAll.dpstrInfo.insrdDpstrSameYn = this.glv_CustInfoAll.eltrnSaveData.insrdDpstrSameYn // 피보험자예금주동일여부
      this.glv_CustInfoAll.dpstrInfo.dpstrIdpnYn = this.glv_CustInfoAll.eltrnSaveData.dpstrIdpnYn // 예금주독립여부
      // 예금주관계정보설정
      inputDataTemp = {}
      inputDataTemp.agtFileId = this.glv_CustInfoAll.eltrnSaveData.dpstrMobslChnlCustId
      inputDataTemp.insrdNm = this.glv_CustInfoAll.eltrnSaveData.dpstrNm
      this.fn_ServiceData('S5', inputDataTemp)
      break
    case 'S5': // 채널고객 기본 정보(예금주)191217 추가
      this.glv_CustInfoAll.dpstrInfo.detail = PSContRltnrUtil.fn_CustInfoList(resData) // 예금주 정보
      inputDataTemp = {}
      inputDataTemp.dpstrKnb = this.glv_CustInfoAll.dpstrInfo.detail.knb
      inputDataTemp.dpstrNm = this.glv_CustInfoAll.dpstrInfo.detail.custNm
      this.fn_ServiceData('S6', inputDataTemp)
      break
    case 'S6': // 채널고객 기본 정보(예금주) BPID조회
      this.glv_CustInfoAll.dpstrInfo.detail.partnerId = resData.businesspartner.partner
      this.fnCallBack(this.glv_CustInfoAll)
      break
    default:
      break
  } // end switch
}
/************************************************************************************************
 * Function명  : fn_ServiceFaultHandler
 * 설명        : 에러 이벤트
 ************************************************************************************************/
PSElstUtil.fn_ServiceFaultHandler = function (event, serviceName) {
  if (event) {
    event.serviceName = serviceName
    this.fnCallBack(event)
  } else {
    event = {serviceName: serviceName}
    this.fnCallBack(event)
  } // end else if
  console.log(serviceName + ' :: ' + event)
}

export default PSElstUtil
// eslint-disable-next-line
